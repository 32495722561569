import React from "react";
import firebase from "firebase";
import { navigate, Link } from "gatsby";
import axios from "axios";
import { handleSignOut } from "./signout";
import { isBrowser } from "services/general";
import VerifyEmailModal from "../VerifyEmailModal";
import {
	isAccessCodeValid,
	isEmailDomainValid,
	redirectBasedOnPermissions,
} from "./permissions";

import { hasRoles } from "./user";

export const getUserData = async ({ authUid }) => {
	const filteredUsers = await firebase
		.firestore()
		.collection("users")
		.where("authUid", "==", authUid)
		.get();
	let userData;

	filteredUsers.forEach((user) => {
		userData = { sex: user?.gender || "", ...user.data(), id: user.id };
	});

	if (userData) userData.permissions = await getUserPermissions(userData);

	return userData;
};

export const getUserPermissions = async ({ accessCode = "", email }) => {
	let accessRule;
	// check email
	let userDomain = email.split("@")[1];

	const accessRulesViaDomain = await firebase
		.firestore()
		.collection("whitelist")
		.where("subdomain", "==", process.env.GATSBY_WEBSITE_URL)
		.where("domains", "array-contains", userDomain)
		.get();

	accessRulesViaDomain.forEach((rule) => {
		accessRule = { ...rule.data(), id: rule.id };
	});

	if (accessRule) {
		return accessRule.programCodes.map((code) => Buffer.from(code));
	}

	if (accessCode && accessCode !== "") {
		const accessRulesViaCode = await firebase
			.firestore()
			.collection("accessRules")
			.where("code", "==", accessCode)
			.get();

		accessRulesViaCode.forEach((rule) => {
			accessRule = { ...rule.data(), id: rule.id };
		});
	}
	if (accessRule) {
		return accessRule.programCodes.map((code) => Buffer.from(code));
	}

	// domain not included and access code provided and no access code provided
	return [];
};

export const decodePermissions = (permissions) => {
	return (
		permissions?.map((permission) => Buffer(permission).toString().slice(-5)) ||
		[]
	);
};

export const getUserAddresses = async ({ addressesId }) => {
	let filteredAddress = await firebase
		.firestore()
		.collection("addresses")
		.doc(addressesId || " ")
		.get();
	if (filteredAddress.data())
		filteredAddress = {
			...filteredAddress.data(),
			id: filteredAddress.id,
		};
	else filteredAddress = {};

	return filteredAddress;
};

export const handleCustomSignIn = async ({ token }) => {
	let response = await firebase.auth().signInWithCustomToken(token);
	try {
		const isVerified =
			process.env.GATSBY_ENV === "production"
				? response?.user?.emailVerified
				: true;
		if (isVerified) {
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
			const authUid = response?.user?.uid;

			let userData = (await getUserData({ authUid })) || {};
			userData.domain = await isEmailDomainValid(response?.user.email);

			let addressesId = userData?.addresses;
			let userAddresses = await getUserAddresses({ addressesId });
			let { coaches, accessRule } = await isAccessCodeValid(
				userData?.accessCode
			);

			userData.coaches = coaches;
			let healthSurveys = await getSurveyCollection({ userId: userData.id });

			if (!!!accessRule) {
				if (isBrowser()) {
					accessRule = sessionStorage.getItem("accessRule");
				}
			}

			// Save user data and addresses to session
			if (isBrowser()) {
				sessionStorage.setItem("coaches", JSON.stringify(coaches || []));
				sessionStorage.setItem("userData", JSON.stringify({ ...userData }));
				sessionStorage.setItem("addresses", JSON.stringify(userAddresses));
				sessionStorage.setItem("healthSurveys", JSON.stringify(healthSurveys));
				sessionStorage.setItem("accessRule", JSON.stringify(accessRule || {}));
			}

			// if no role proceed to enrollment
			if (!hasRoles()) {
				if (!userData.domain.validity) {
					return navigate("/access-code");
				}
				return navigate("/enrollment");
			}

			let chosen =
				sessionStorage.getItem("programChosen") ||
				localStorage.getItem("programChosen");

			if (chosen) {
				redirectBasedOnPermissions(chosen, userData?.permissions);
			}
		} else {
			await handleSignOut({ redirect: false, error: true });
			return;
		}
	} catch (error) {
		console.log(error);
	}
};

export const handleEmailLogin = async ({
	values,
	callBack,
	setMessage,
	resetForm,
	errorCallback,
	dispatch,
	setLoading,
	location,
	redirectTo,
}) => {
	let email = values?.email;
	let password = values?.password;

	try {
		// if (!!user?.authUid) {
		// Sign in user
		let response = await firebase
			.auth()
			.signInWithEmailAndPassword(email, password);

		// Handle if emil is verified through email verification
		const isVerified =
			process.env.GATSBY_ENV === "production"
				? response?.user?.emailVerified
				: true;
		if (isVerified) {
			let idToken = await response.user.getIdToken(true);
			await axios.post(
				process.env.GATSBY_AUTH + "/login",
				{ idToken },
				{ withCredentials: true }
			);
			firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
			const authUid = response?.user?.uid;
			let userData = (await getUserData({ authUid })) || {};
			userData.domain = await isEmailDomainValid(response.user.email);
			let addressesId = userData?.addresses;
			let userAddresses = await getUserAddresses({ addressesId });

			let { coaches, accessRule, validity } = await isAccessCodeValid(
				userData?.accessCode
			);

			if (!!accessRule && validity === "inactive") {
				let err = new Error("Inactive Access Code");
				err.code = "auth/old-access-code";
				await handleSignOut({ redirect: false, error: err });
				throw err;
			}

			if (!!!accessRule) {
				if (isBrowser()) {
					accessRule = JSON.parse(sessionStorage.getItem("accessRule"));
				}
			}

			let healthSurveys = await getSurveyCollection({ userId: userData.id });
			// Save user data and addresses to session
			if (isBrowser()) {
				sessionStorage.setItem("userData", JSON.stringify({ ...userData }));
				sessionStorage.setItem("addresses", JSON.stringify(userAddresses));
				sessionStorage.setItem("coaches", JSON.stringify(coaches || []));
				sessionStorage.setItem("healthSurveys", JSON.stringify(healthSurveys));
				sessionStorage.setItem("accessRule", JSON.stringify(accessRule || {}));
			}

			// if (resetForm) resetForm()
			// if (callBack) callBack()

			// if no role proceed to enrollment
			if (!hasRoles()) {
				if (!userData.domain.validity) {
					return navigate("/access-code");
				}
				return navigate("/enrollment");
			}

			if (redirectTo) {
				return navigate(`${redirectTo}`);
			}

			let chosen =
				sessionStorage.getItem("programChosen") ||
				localStorage.getItem("programChosen");
			if (chosen) {
				redirectBasedOnPermissions(chosen, userData?.permissions);
				sessionStorage.removeItem("programChosen");
				localStorage.removeItem("programChosen");
			} else {
				return navigate("/");
			}
		} else {
			// If not verified, prevent login and show modal
			await handleSignOut({ redirect: false, error: true });
			setLoading(false);
			dispatch({
				type: "SHOW_MODAL",
				payload: {
					heading: "Your email is not verified",
					isCard: true,
					headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
					content: (
						<VerifyEmailModal user={response?.user} location={location} />
					),
				},
			});
			return;
		}
	} catch (error) {
		console.log({ error });
		if (errorCallback) errorCallback();

		switch (error?.code) {
			case "auth/old-access-code":
				setMessage({
					type: "danger",
					content: {
						code: error.code,
						message:
							"Your company may no longer be enrolled in the mental health program due to changes in your benefit coverage. To explore alternative avenues for support, please reach out to your HR representative.",
					},
				});
				break;

			case "auth/wrong-password":
				setMessage({
					type: "danger",
					content: {
						code: error.code,
						message:
							"Invalid email or password. Please check if your credentials are correct before logging in again.",
					},
				});
				break;

			case "auth/user-not-found":
				setMessage({
					type: "danger",
					content: {
						code: error.code,
						message: (
							<span>
								The email you entered does not match our records. Please{" "}
								<Link to="/verify-email">enroll</Link> to avail of our services
							</span>
						),
					},
				});
				break;

			default:
				setMessage({
					type: "danger",
					content: { code: error.code, message: error.message },
				});
		}
	}
};

export const getSurveyCollection = async ({ userId }) => {
	let healthSurveys = [];

	if (userId) {
		const healthSurveysDocuments = await firebase
			.firestore()
			.collection("users")
			.doc(userId || "")
			.collection("healthSurveys")
			.get();

		healthSurveysDocuments.forEach((survey) => {
			healthSurveys.push({
				...survey.data(),
				created: survey.data().created.toDate(),
				validUntil: survey.data().validUntil.toDate(),
				id: survey.id,
			});
		});
	}

	return healthSurveys;
};
