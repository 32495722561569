import React, { Fragment } from "react";
import { navigate } from "gatsby";
import classNames from "classnames";
import Button from "./Button.js";

import styles from "./utils/elements.module.scss";

const ResponsiveActionButtons = ({ isMobile, submit, next, back, loading }) => (
	<Fragment>
		<div className="is-flex is-full is-justify-content-center columns mt-1-mobile">
			{back && (
				<button
					type="button"
					onClick={() => {
						if (back?.callback) {
							back.callback();
							return;
						}
						navigate(back.link);
					}}
					className={classNames(
						"button-size mr-1 mr-1-mobile column	mr-0-mobile is-1 is-2-mobile button is-large",
						{
							"is-hidden-desktop is-hidden-tablet": !!isMobile,
							"is-hidden-mobile": !isMobile,
						},
						styles["backButton"]
					)}
				>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 32 32"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M20.0664 26.5599L11.3731 17.8666C10.3464 16.8399 10.3464 15.1599 11.3731 14.1333L20.0664 5.43994"
							stroke="#EE7423"
							stroke-width="2.66667"
							stroke-miterlimit="10"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>
			)}
			{next && (
				<Button
					type="button"
					onClick={() => {
						if (next.callback) {
							next.callback();
							return;
						}
						if (next?.link) navigate(next.link);
					}}
					className={classNames(
						"px-2 px-1-mobile ml-1 ml-0-mobile column button",
						{
							"is-hidden-desktop is-hidden-tablet": !!isMobile,
							"is-hidden-mobile": !isMobile,
						},
						{
							"is-loading": next.loading,
						}
					)}
					size={isMobile ? "small" : "medium"}
					color="secondary"
					disabled={next.loading}
				>
					{next.label}
				</Button>
			)}
			{submit && (
				<Button
					className={classNames(
						"button-size px-2 px-1-mobile px-1-tablet ml-1 ml-0-tablet ml-0-tablet column is-10-mobile is-10-tablet is-4 button is-large is-secondary has-text-weight-bold",
						{
							"is-hidden-desktop is-hidden-tablet": !!isMobile,
							"is-hidden-mobile": !isMobile,
						},
						{
							"is-loading": submit.loading,
						},
						styles["nextButton"]
					)}
					type="submit"
					disabled={submit.loading}
					onClick={() => navigate(submit.link)}
				>
					{submit.label}
				</Button>
			)}
		</div>
	</Fragment>
);

const ActionButtons = ({ submit, next, back }) => (
	<div className="buttons is-centered">
		<ResponsiveActionButtons submit={submit} next={next} back={back} />
		<ResponsiveActionButtons isMobile submit={submit} next={next} back={back} />
	</div>
);

export default ActionButtons;
