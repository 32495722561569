import React, { Fragment, memo, useMemo, useState } from "react";
import axios from "axios";

import Loading from "elements/Loading";
import { isBrowser } from "../../services/general";
import { hasSignedInUser } from "./services/user";
import { handleCustomSignIn } from "./services/signin";
import { handleSignOut } from "./services/signout";

const AuthRoute = ({ children }) => {
	const [loading, setLoading] = useState(false);

	useMemo(() => {
		const fetchUserData = async () => {
			if (isBrowser() && !hasSignedInUser()) {
				let params = new URLSearchParams(window.location.search);
				let programChosen = params.get("program");
				if (programChosen) {
					sessionStorage.setItem("programChosen", programChosen);
				}
				try {
					setLoading(true);
					let res = await axios(process.env.GATSBY_AUTH + "/verify", {
						method: "post",
						withCredentials: true,
					});
					if (res.data.token)
						await handleCustomSignIn({ token: res.data.token });
					setLoading(false);
				} catch (error) {
					await handleSignOut({ redirect: false, error });
					return setLoading(false);
				}
			}
		};
		fetchUserData();

		return () => {
			setLoading(false);
		};
	}, []);

	return <Fragment>{loading ? <Loading /> : children}</Fragment>;
};

export default memo(AuthRoute);
