import { camelizeKeys } from "humps";
import { isBrowser } from "services/general";
import _ from "lodash";

export const hasSignedInUser = () => {
	if (isBrowser()) {
		const USER =
			Object.keys(window.sessionStorage).filter((item) =>
				item.startsWith("firebase:authUser")
			)[0] || "medstaffData" in window.sessionStorage;

		return USER;
	}
};

export const getSignedInUser = () => {
	if (isBrowser()) {
		let fauthUser = Object.keys(window.sessionStorage).filter((item) =>
			item.startsWith("firebase:authUser")
		)[0];
		let authUser = JSON.parse(sessionStorage.getItem(fauthUser));
		// let authUser = JSON.parse(sessionStorage.getItem(authUser))
		let userData = camelizeKeys(JSON.parse(sessionStorage.getItem("userData")));
		let accessRule = JSON.parse(sessionStorage.getItem("accessRule")) || {};
		let addresses = JSON.parse(sessionStorage.getItem("addresses"));
		const medstaffData = JSON.parse(sessionStorage.getItem("medstaffData"));
		let healthSurveys = JSON.parse(sessionStorage.getItem("healthSurveys"));

		return {
			accessRule,
			authUser,
			userData,
			addresses,
			medstaffData,
			healthSurveys,
		};
	} else return {};
};

export const hasRoles = () => {
	const { userData, medstaffData } = getSignedInUser();

	let hasRoles = false;

	if (userData) {
		let activeRole = userData?.roles?.find(
			(role) => role?.subdomain === process.env.GATSBY_WEBSITE_URL
		);
		if (activeRole) hasRoles = true;
	}
	if (medstaffData) return true;

	return hasRoles;
};

export const isEmailVerified = () => {
	const { authUser } = getSignedInUser();

	if (authUser && authUser.emailVerified) {
		return true;
	}
	return false;
};

export const getCurrentProgram = () => {
	return isBrowser() ? sessionStorage.getItem("programChosen") : "";
};

export const getUserAccessCode = () => {
	return isBrowser() ? JSON.parse(sessionStorage.getItem("accessRule")) : {};
};

export const isProfileComplete = () => {
	const { userData } = getSignedInUser();

	let requiredEnrollmentFields = [
		"firstName",
		"lastName",
		"birthday",
		"birthday.year",
		"birthday.month",
		"birthday.date",
		"sex",
		"email",
		"mobileNumber",
		"emergencyContact",
		"emergencyContact.name",
		"emergencyContact.relationship",
		"emergencyContact.mobileNumber",
		"employeeNumber",
		"department",
	];

	// if (userData?.domain?.siteOptions?.length > 0) {
	//   requiredEnrollmentFields.push("site")
	// }

	// profileComplete is true if all required fields have a value
	let profileComplete = requiredEnrollmentFields.every((field) => {
		return !!_.get(userData, field);
	});

	return profileComplete;
};
