export function isAllowedOnPage({ permissions, location }) {
	const MH_MODULES = ["mind", "nutritionist"];
	const PH_MODULES = ["body"];

	if (location.includes(MH_MODULES[0]) || location.includes(MH_MODULES[1])) {
		return permissions.includes("SOLMH");
	} else if (location.includes(PH_MODULES[0])) {
		return permissions.includes("SOLPH");
	} else {
		return true;
	}
}
