import { navigate } from "gatsby";
import firebase from "firebase";
import { decodePermissions } from "./signin";

import { isBrowser } from "services/general";

export const isEmailDomainValid = async (email) => {
	let accessRule;
	let userDomain = email.split("@")[1];

	const accessRulesViaDomain = await firebase
		.firestore()
		.collection("whitelist")
		.where("subdomain", "==", process.env.GATSBY_WEBSITE_URL)
		.where("domains", "array-contains", userDomain)
		.get();

	accessRulesViaDomain.forEach((rule) => {
		accessRule = { ...rule.data(), id: rule.id };
	});

	if (isBrowser()) {
		sessionStorage.setItem("accessRule", JSON.stringify(accessRule || {}));
	}

	if (accessRule)
		return {
			eligiblePrograms: accessRule.programCodes,
			validity: true,
			orgTradename: accessRule.organizationTradeName,
			siteOptions: accessRule.siteOptions,
		};
	return { validity: false, orgTradename: "" };
};

export const isAccessCodeValid = async (accessCode) => {
	let accessRule;
	let coaches = [];
	if (accessCode && accessCode !== "") {
		try {
			const accessRulesViaCode = await firebase
				.firestore()
				.collection("accessRules")
				.where("code", "==", accessCode || "")
				.get();

			accessRulesViaCode.forEach((rule) => {
				accessRule = rule.data();
			});
		} catch (error) {
			return { validity: "inactive", orgTradename: "", coaches: [] };
		}
	}

	if (isBrowser()) {
		JSON.parse(sessionStorage.getItem("accessRule"));
	}

	if (accessRule) {
		const queryCoaches = await firebase
			.firestore()
			.collection(`accessRules/${accessRule?.id}/coaches`)
			.get();

		if (queryCoaches)
			queryCoaches.forEach((coach) => {
				coaches.push({ ...coach.data(), id: coach.id });
			});
		return {
			eligiblePrograms: accessRule.programCodes,
			siteOptions: accessRule.siteOptions,
			validity: accessRule.status,
			orgTradename: accessRule.organizationTradeName,
			coaches,
			accessRule,
		};
	}

	return { validity: "inactive", orgTradename: "", coaches: [] };
};

export const redirectBasedOnPermissions = (chosen, permissions) => {
	let program, programUrl;
	let currentPath;

	// gets current window path
	window?.location.pathname
		? (currentPath = window?.location.pathname)
		: (currentPath = "");

	// static pages to be excluded
	let excludedRedirectPaths = [
		"/terms-and-conditions",
		"/privacy-policy",
		"/informed-consent",
		"/maxicare-terms-and-conditions",
		"/workplace-wellbeing",
	];

	// excludes static pages in the redirect logic
	if (excludedRedirectPaths.includes(currentPath)) {
		navigate(currentPath);
	} else {
		switch (chosen.toLowerCase()) {
			case "physical health":
				program = process.env.GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE.toString().slice(
					-5
				);
				break;
			case "mental health":
				program = process.env.GATSBY_MENTAL_HEALTH_PROGRAM_CODE.toString().slice(
					-5
				);
				break;
			default:
				program = process.env.GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE.toString().slice(
					-5
				);
		}

		const decodedPermissions = decodePermissions(permissions);
		const valid = decodedPermissions.includes(program);

		if (valid) {
			switch (chosen.toLowerCase()) {
				case "physical health":
					programUrl = "body";
					break;
				case "mental health":
					programUrl = "mind";
					break;
				default:
					programUrl = "coaching";
			}
			navigate(`/booking/${programUrl}/intake`);
		} else {
			navigate(`/services`, { state: { invalid: true } });
		}
	}
};

export const getEquivalentPermissions = (candidatePermission, permissions) => {
	let programs = permissions.map((perm) => perm.slice(-5));
	let generalProgram = candidatePermission.slice(-5);

	return programs.includes(generalProgram) ? candidatePermission : "";
};

export const getPermissionCode = (chosen, permissions) => {
	const decodedPermissions = decodePermissions(permissions);
	switch (chosen) {
		case "Physical Health":
			chosen = process.env.GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE;
			break;
		case "Mental Health":
		default:
			chosen = process.env.GATSBY_MENTAL_HEALTH_PROGRAM_CODE;
	}
	return getEquivalentPermissions(chosen, decodedPermissions);
};
