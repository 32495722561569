import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					property: `og:title`,
					content: title,
				},
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image:url`,
					content: process.env.GATSBY_THUMBNAIL_URL,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:image`,
					content: process.env.GATSBY_THUMBNAIL_URL,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: "robots",
					content: "noindex",
				},
			].concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
